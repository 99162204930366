import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Article, Issue, Organization, Publication, Soundfile, User } from 'src/app/models';
import { OrganizationService } from 'src/app/service/organization.service';
import { PublicationService } from 'src/app/service/publication.service';
import { UserService } from 'src/app/service/user.service';


export interface DialogData {
  user: User;
  orgs: Organization[];
  pubs: Publication[];
  orgId: string;
}

@Component({
  selector: 'app-user-dialog',
  templateUrl: 'user-dialog.html',
  styleUrls: ['user-dialog.scss']
})
export class UserDialogComponent {

  pubToAdd: string;


  constructor(
    public dialogRef: MatDialogRef<UserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private userService: UserService, private orgService: OrganizationService, private pubService: PublicationService) {
      if (!this.data.orgs) {
        this.orgService.getList().subscribe(orgs => {
          this.data.orgs = orgs;
        });
        this.pubService.getList().subscribe(pubs => {
          this.data.pubs = pubs;
        });
      }
  }

  save(): void {
    if (!this.data.user._id) {
      this.data.user.password = 'trapped';
    }
    console.log(this.data.user);
    this.userService.saveItem(this.data.user).subscribe(result => {
      this.dialogRef.close();
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  unlink(pub: Publication): void {
    this.data.user.extraPubIds.splice(this.data.user.extraPubIds.indexOf(pub._id), 1);
}

link(pub: Publication): void {
  if (!this.data.user.extraPubIds) {
    this.data.user.extraPubIds = [pub._id];
  } else {
    this.data.user.extraPubIds.push(pub._id);
  }

}

  getPubName(id: string): string {
    for (const pub of this.data.pubs) {
      if (pub._id === id) {
        return pub.name;
      }
    }
  }

}

