export interface User {
  _id: string;
  name: string;
  info: string;
  isNarrator: boolean;
  email: string;
  role: string;
  password: string;
  provider: string;
  salt: string;
  customer: string;
  defaultPubId: string;
  latestPubIds: [string];
  extraPubIds: [string];
}

export interface PubGroup {
  _id?: string;
  name: string;
  info: string;
  active: boolean;
  publications: [ string ];
  defaultPub: string;
}

export interface Organization {
  _id?: string;
  name?: string;
  info?: string;
  contact_name?: string;
  contact_email?: string;
  delivery?: {
    title?: string,
    address1?: string,
    address2?: string,
    city?: string,
    postcode?: string,
  };
  invoice?: {
    title?: string,
    address1?: string,
    address2?: string,
    city?: string,
    postcode?: string,
  };
  address1?: string;
  address2?: string;
  city?: string;
  postcode?: string;
  organizationNumber?: string;
  customerNumber?: string;
  subscription_type?: string;
  active?: boolean;
  dopaId?: string;
}

export interface Imagefile {
  _id: string;
  name: string;
  info: string;
  issue: string;
  article: string;
  publication: string;
  quotation: string;
  filename: string;
  thumbFilename: string;
  alt: string;
  title: string;
  height: number;
  width: number;
  active: boolean;
  updated: string;
}

export interface Textfile {
  _id: string;
  name: string;
  info: string;
  issue: string;
  article: string;
  publication: string;
  quotation: string;
  filename: string;
  active: boolean;
  updated: string;
}

export interface Soundfile {
  _id: string;
  name: string;
  info: string;
  filename: string;
  filenameWav: string;
  filenameMp3: string;
  filenameOgg: string;
  filenameOpus: string;
  filenameAac: string;
  duration: number;
  type: string;
  publication: string;
  issue: string;
  article: string;
  quotation: string;
  active: boolean;
  updated: Date;
}

export interface Issue {
  _id?: string;
  name: string;
  info?: string;
  publication: string;
  actionText?: string;
  currentProcessItem?: number;
  date?: Date;
  availableFromDate?: Date;
}

export interface Page {
  _id?: string;
  title: string;
  content: string;
  prio?: number;
  icon?: string;
  tooltip?: string;
  publication: string;
  updatedAt?: Date;
  createdAt?: Date;
  slug?: string;
  key?: string;
  hiddenFromMenu?: boolean;
}

export interface Order {
  _id?: string;
  name: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export enum AnalyticsEvent {
  pageLoad = 'pageLoad',
  playStart = 'playStart',
  playEnd = 'playEnd',
  playPause = 'playPause',
  sessionStart = 'sessionStart',
  sessionEnd = 'sessionEnd',
}

export interface Analytics {
  _id?: string;
  domain: string,
  path: string,
  publication?: string,
  issue?: string,
  article?: string,
  page?: string,
  event: AnalyticsEvent,
  title?: string,
  session: string,
  origin: string,
  updatedAt?: Date;
  createdAt?: Date;
}

export interface Quotation {
  _id?: string;
  name: string;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface Message {
  _id?: string;
  publication?: string;
  issue?: string;
  name?: string;
  messageType?: string;
  title?: string;
  content?: string;
  phone?: string;
  email?: string;
  archived?: boolean;
  updatedAt?: Date;
  createdAt?: Date;
}

export interface Article {
  _id: string;
  name: string;
  webName?: string;
  info: string;
  level: string;
  page: number;
  category?: string;
  tags?: string[];
  priority?: number;
  issue?: string;
  highLight?: boolean;
  code?: string;
  ingress?: string;
  text?: string;
  publication?: string;
  soundfile?: string;
  duration?: number;
  durationDirty?: boolean;
  active?: boolean;
  export?: boolean;
  sentToWFTab1?: boolean;
  sentToWFTab2?: boolean;
  share?: boolean;
  reuse?: boolean;
  reuseUntil?: Date;
  qrImagefile?: string;
  qrUrl?: string;
  qrSvgImage?: string;
  byLine?: string;
  narratedBy?: string;
  hasSound?: boolean;
  hasImages?: boolean;
  hasNrOfImages?: number;
  updated?: string;
  created?: string;
  updatedDate?: string;
  createdDate?: Date;
  path?: string;
  changed?: boolean;
  mark?: boolean;
  listenCount?: number;
  slug?: string;
  issueSlug?: string;
}

export interface Publication {
    _id: string;
    name: string;
    info: string;
    description: string;
    nextIssueNumber: number;
    pubGroup: string;
    customer: string;
    contact: string;
    contactMessage: string;
    defaultArticleCategory: string;
    defaultArticleName: string;
    defaultArticleLevel: string;
    posterimage: string;
    icon: string;
    podcastType: number;
    podcastIcon: string;
    podcastUrlApple: string;
    podcastUrlGoogle: string;
    podcastUrlSpotify: string;
    singleFilePodcast: boolean;
    podcastEmail: string;
    appUrlApple: string;
    appUrlGoogle: string;
    useNextGenPlayer: boolean;
    webSite: string;
    dtbookBaseUrl: string;
    dtbookFilename: string;
    legimusFilename: string;
    sendToLegimus: boolean;
    isSharing: boolean;
    hasSubscribers: boolean;
    usePush: boolean;
    shareByDefault: boolean;
    active: boolean;
    printDaisyIdentifyer: boolean;
    playNextAuto: boolean;
    rescanBlipSayAfter: number;
    showTextInPlayer: boolean;
    categories: string[];
    tags?: string[];
    playerBaseUrl: string;
    pushTopic: string;
    dPushId: string;
    dPushTitleTemplate: string;
    dPushMessageTemplate: string;
    mailTitleTemplate: string;
    mailMessageTemplate: string;
    mailMessageHtmlTemplate: string;
    processItems: {
      prio?: number;
      name?: string;
      id?: number;
      fetchDaisy?: boolean;
      importDaisy?: boolean;
      fetchRss?: boolean;
      importRss?: boolean;
      importFtp?: boolean;
      alterDeactivateH1?: boolean;
      alterExportH2?: boolean;
      alterActivateAll?: boolean;
      alterToH1?: boolean;
      alterToH2?: boolean;
      alterGuessH2?: boolean;
      alterCopyNameToInfo?: boolean;
      alterRemoveNumbersStopSpace?: boolean;
      alterRemoveNumbers?: boolean;
      alterPrettify?: boolean;
      alterAddNumbers?: boolean;
      exportDaisy?: boolean;
      exportLegimus?: boolean;
      exportRss?: boolean;
      exportDtbook?: boolean;
      exportFtp?: boolean;
      exportWordPress?: boolean;
      exportDrupal?: boolean;
      exportSingleMp3?: boolean;
      availableFromDateNow?: boolean;
      sendPush?: boolean;
      sendMailToSubscribers?: boolean;
      done?: boolean
    }[];
    audio: {
      mp3: {
        bitrate: number,
      }
    };
    wfsettings: {
      enable: boolean,
      enableDaisy: boolean,
      enableRSS: boolean,
      RESOURCE_DIR_1: string,
      RESOURCE_DIR_2: string,
      TAB_0_OUTPUT_FILENAME: string,
      TAB_0_USERNAME: string,
      TAB_0_PASSWORD: string,
      TAB_0_FILENAME: string,
      TAB_0_DIRECTORY: string,
      TAB_0_FTP_URL: string,
      TAB_1_OUTPUT_FILENAME: string,
      TAB_1_USERNAME: string,
      TAB_1_PASSWORD: string,
      TAB_1_FILENAME: string,
      TAB_1_DIRECTORY: string,
      TAB_1_FTP_URL: string
    };
    importDaisy: {
      enable: boolean,
      username: string,
      password: string,
      filename: string,
      directory: string,
      url: string,
      timeOnFtp: number,
      mailList: string,
      mailSent: boolean
    };
    importRSS: {
      enable: boolean,
      username: string,
      password: string,
      filename: string,
      directory: string,
      url: string
    };
    exportToFtp: {
      enable: boolean,
      singleFile: boolean,
      username: string,
      password: string,
      pattern: string,
      filename: string,
      directory: string,
      url: string
    };
    exportToDtbook: {
      enable: boolean
    };
    allowAnonUpload: boolean;
    exportWordPress: {
      enable: boolean,
      url: string,
      user: string,
      pass: string,
      base: string,
      vendor: string
    };
    whitelistDomains: {
      type: string
    };
    emailOnUploadFilter: string;
    emailOnUploadUsers: User[];
    emailOnSubscChangesUsers: User[];
    public: boolean;
    publicationType: {
      blipsay: boolean;
      newspaper: boolean;
      audioBook: boolean;
      websay: boolean;
      blipsayCustom: boolean;
    };
    playConf: any;
  }
