<div class="theme-wrapper" [ngClass]="selectedTheme" [style.background-color]="backgroundColor">
  <div class="mat-app-background theme-wrapper">
    <div *ngIf="!showMenu">
      <router-outlet></router-outlet>
    </div>

    <mat-toolbar *ngIf="showToolbar" class="ljp-toolbar" color="primary"
      [ngStyle]="{ height: toolbarHeight ? toolbarHeight : '64px' }">      
      <div class="menu-button-wrapper" *ngIf="(isHandset$ | async)">
        <button mat-mini-fab color="primary" (click)="sidenav.toggle()">
          <mat-icon>menu</mat-icon>
        </button>
        <!--<button mat-flat-button (click)="sidenav.toggle()" color="primary" style="margin-top:3px;">Meny</button>-->
      </div>
      <span *ngIf="toolbarImageUrl === 'assets/y-bladet-rvn.png'" class="toolbar-spacer"></span>
      <img *ngIf="!toolbarImageUrl" class="toolbar-image" src="/assets/Darub_punkt_110x110.png" width="30px" height="30px" style="margin-right: 5px;"/>
      <img class="toolbar-image" *ngIf="toolbarImageUrl" src="{{toolbarImageUrl}}" />
      <div *ngIf="!toolbarImageUrl">{{toolbarTitle}} <span style="font-size: 14px;" *ngFor="let item of toolbarInfo; let last = last"><a
            mat-button routerLink="{{item.url}}"> {{ item.title }} </a>{{ last ? '' : '/' }}</span>
          </div>
      <span *ngIf="toolbarImageUrl === 'assets/y-bladet-rvn.png'" class="toolbar-spacer"></span>
      <img class="toolbar-image" *ngIf="toolbarImageUrl === 'assets/y-bladet-rvn.png'" style="height: 30px;" src="assets/rvn_svart.png" />
      <span *ngIf="!toolbarImageUrl" class="toolbar-spacer"></span>
      <ng-container *ngFor="let item of contextMenu">
        <button mat-button *ngIf="item.toolbar" (click)="sendAction(item)" matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000"> {{
          item.title }}
        </button>
      </ng-container>


      <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showUserMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <ng-container *ngFor="let item of userMenu">
          <button *ngIf="item.action==='route'" routerLink="{{ item.url }}" mat-menu-item [innerHtml]="item.title"
            matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000"></button>
          <button *ngIf="item.action==='function'" (click)="item.func()" mat-menu-item [innerHtml]="item.title"
            matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000"></button>
        </ng-container>
      </mat-menu>
    </mat-toolbar>
    <mat-spinner *ngIf="isLoading" color="accent" class="ljp-spinner"></mat-spinner>

    <mat-sidenav-container *ngIf="showMenu" class="mat-sidenav-container"
      [ngStyle]="{ 'padding-top': !showToolbar ? '0px' : undefined }">
      <!--<mat-sidenav mode="over">-->
      <mat-sidenav class="mat-sidenav" mode="side" [opened]="!(isHandset$ | async)">

        <mat-action-list *ngIf="contextMenu.length > 0">
          <div mat-subheader style="width: 100%; text-align: center; margin-bottom: 0px; padding-bottom: 0px;"><span
              style="margin-left: auto;
          margin-right: auto;">Meny</span></div>

          <div *ngFor="let item of contextMenu">

            <div *ngIf="item.action === 'live'" style="text-align: center;">
              <div class="live-wrapper">
                <div class="live-header">Lyssna på direktsändning</div>
                <div class="live-button">
                  <img src="/assets/ockero_logo_only.png" class="live-image" (click)="sendAction(item)" />
                  <button mat-fab (click)="sendAction(item)" color="primary">
                    <mat-icon *ngIf="!audioState.playing || audioState.readableDuration !== 'Invalid date'">play_arrow</mat-icon>
                    <mat-icon *ngIf="audioState.playing && audioState.readableDuration === 'Invalid date'">pause</mat-icon>
                  </button>
                  <!--<img src="/assets/ockero_logo_only.png" class="live-image" (click)="sendAction(item)" />-->
                </div>
              </div>
            </div>
            <mat-divider *ngIf="item.action === 'divider'"></mat-divider>

            <mat-list-item *ngIf="!item.subMenu && item.action !== 'divider' && item.action !== 'live'"
              matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000" (click)="sendAction(item)" class="menu-item">

              <img *ngIf="item.image" src="{{item.image}}" class="menu-item-image" />
              <mat-icon *ngIf="!item.image" mat-list-icon [color]="item.color">{{ item.icon }}</mat-icon>

              <mat-form-field *ngIf="item.action === 'search'" class="" style="width: 80%;">
                <mat-label>Sök</mat-label>
                <input matInput [(ngModel)]="item.data" (keydown.enter)="sendAction(item)">
                <button *ngIf="item.data" matSuffix mat-icon-button aria-label="töm sökruta"
                  (click)="item.data=''; sendAction(contextMenu[0])">
                  <mat-icon>close</mat-icon>
                </button>
                <!--<button matSuffix mat-icon-button aria-label="redigera" (click)="edit(item)" color="primary">
                <mat-icon>edit</mat-icon>
              </button>-->
              </mat-form-field>

              <div *ngIf="!item.image" mat-line [color]="item.color">{{ item.title }}</div>
              <div *ngIf="!item.image" mat-line class="sub-title"> {{ item.subTitle }} </div>

            </mat-list-item>



            <mat-list-item *ngIf="item.subMenu && item.action !== 'divider' && item.action !== 'live'"
              matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000" class="menu-item">
              <img *ngIf="item.image" src="{{item.image}}" class="menu-item-image" />

              <mat-icon *ngIf="!item.image" mat-list-icon>{{ item.icon }}</mat-icon>
              <div *ngIf="!item.image" [matMenuTriggerFor]="menu" mat-line>{{ item.title }}</div>

              <mat-menu #menu="matMenu" xPosition="after">
                <button *ngFor="let sub of item.subMenu" mat-menu-item (click)="sendAction(sub)">{{sub.title}}</button>
              </mat-menu>
            </mat-list-item>

          </div>
        </mat-action-list>
        <mat-divider></mat-divider>

        <mat-nav-list *ngIf="navigationMenu.length > 0">
          <div mat-subheader>Navigering</div>
          <mat-list-item *ngFor="let item of navigationMenu" class="menu-item">
            <mat-icon mat-list-icon>{{ item.icon }}</mat-icon>
            <a matLine routerLink="{{ item.url }}" matTooltip="{{ item.tooltip }}" matTooltipShowDelay="2000">{{ item.title }}</a>
            <div *ngFor="let subItem of item.subMenu" mat-line class="sub-title">
              <a matLine matTooltip="{{ subItem.tooltip }}" matTooltipShowDelay="2000" routerLink="{{ subItem.url }}">{{ subItem.title }}</a>
            </div>
          </mat-list-item>
        </mat-nav-list>

      </mat-sidenav>

      <mat-sidenav-content>
        <div class="RouterOutlet">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>