import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

export interface DialogConfig {
    title?: string,
    content?: string,
    ok?: string,
    close: string
}

@Component({
    templateUrl: './dialog.component.html',
})
export class DialogComponent {

  get dialog(): DialogConfig {
      return this.data;
  }

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: DialogConfig,
      public dialogRef: MatDialogRef<DialogComponent>
  ) {}

}
