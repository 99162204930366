import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';


// import { DialogFormComponent } from './dialog-form.component';
import { DialogComponent } from './dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule
    ],
    declarations: [
        DialogComponent
    ]
})
export class DialogModule {}
